.applicationItem {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.applicationItem svg {
  float: right;
}

.LOGGED_IN {
  background: #fff;
}

.LOGGING_OUT {
  background: yellow;
}

.LOGGED_OUT {
  background: green;
}

.App {
  margin: auto;
  text-align: center;
}